import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { PopoverBase } from '@hse-design/react';
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "popoverbase"
    }}>{`PopoverBase`}</h1>
    <pre><code parentName="pre" {...{}}>{`import { PopoverBase } from '@hse-design/react';
`}</code></pre>
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`PopoverBase - это базовая рамка с тенью, используемая в компоненте Popover.
PopoverBase не содержит логики позиционирования и подходит для создания кастомизированных составных компонентов.`}</p>
    <Playground __position={0} __code={'<PopoverBase>\n  <div className=\"hse-Text_paragraph-small\">\n    A popover is a light roll made from an egg batter similar to that of\n    Yorkshire pudding, typically baked in muffin tins or dedicated popover\n    pans, which have straight-walled sides rather than angled\n  </div>\n</PopoverBase>'} __scope={{
      props,
      DefaultLayout,
      PopoverBase,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PopoverBase mdxType="PopoverBase">
    <div className="hse-Text_paragraph-small">
      A popover is a light roll made from an egg batter similar
      to that of Yorkshire pudding, typically baked in muffin tins
      or dedicated popover pans, which have straight-walled sides
      rather than angled
    </div>
  </PopoverBase>
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "контент-в-края"
    }}>{`Контент в края`}</h3>
    <p>{`Передайте проп `}<inlineCode parentName="p">{`noPadding`}</inlineCode>{`, чтобы убрать стандартный отступ от края.`}</p>
    <Playground __position={1} __code={'<PopoverBase noPadding={true}>\n  <div className=\"hse-Text_paragraph-small\">\n    A popover is a light roll made from an egg batter similar to that of\n    Yorkshire pudding, typically baked in muffin tins or dedicated popover\n    pans, which have straight-walled sides rather than angled\n  </div>\n</PopoverBase>'} __scope={{
      props,
      DefaultLayout,
      PopoverBase,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PopoverBase noPadding={true} mdxType="PopoverBase">
    <div className="hse-Text_paragraph-small">
      A popover is a light roll made from an egg batter similar
      to that of Yorkshire pudding, typically baked in muffin tins
      or dedicated popover pans, which have straight-walled sides
      rather than angled
    </div>
  </PopoverBase>
    </Playground>
    <h2 {...{
      "id": "параметры"
    }}>{`Параметры`}</h2>
    <Props of={PopoverBase} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      